<template lang="">
    <div>
        <v-row><v-col cols="12"></v-col></v-row>
    
        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            SEARCH FILTER
                        </div>
                    </v-card-title>

                    <v-row class="pa-4">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2" v-if="brokerMap.length > 1">
                            <v-autocomplete label="Filter Broker"
                                v-model="selectedBrokerId" :items="brokerMap" item-text="broker" item-value="broker_id"
                                auto-select-first chips clearable deletable-chips small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Filter Book"
                                v-model="selectedBook" :items="filterBookLists" item-text="book" item-value="book"
                                auto-select-first chips clearable deletable-chips multiple small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field label="Volume (Greater & Equal)" v-model="selectedVolume" hide-details="auto"></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-text-field label="Offset" v-model="selectedOffsetStart" hide-details="auto"></v-text-field>
                        </v-col> -->
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker label="Start Date" v-model="selectedStartDate"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-datetime-picker label="End Date" v-model="selectedEndDate"></v-datetime-picker>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <div class="text-left" style="margin-left:20px;margin-top:20px">
                                <div>
                                    <v-btn text icon color="blue lighten-2" @click="showMore()" v-if="!showMoreStatus">
                                        More
                                        <v-icon large>
                                            mdi-chevron-down
                                        </v-icon>
                                    </v-btn>
                                    <v-btn text icon color="blue lighten-2" @click="showMore()" v-if="showMoreStatus">
                                        Fewer
                                        <v-icon large>
                                            mdi-chevron-up
                                        </v-icon>
                                    </v-btn>
                                </div>
                            </div>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4" v-if="showMoreStatus">
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Order By"
                                v-model="selectedOrderBy" :items="filterOrderBy"
                                auto-select-first chips clearable deletable-chips  small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="2">
                            <v-switch style="width: 100%;" v-model="isAsc" :label="`Is Ascending Order?`"></v-switch>
                        </v-col>
                        <v-col cols="12" sm="12" md="4" lg="2" xl="2">
                            <v-autocomplete label="Show Result"
                                v-model="selectedLimit" :items="filterShowResult"
                                auto-select-first chips clearable deletable-chips  small-chips></v-autocomplete>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                            <v-text-field label="Login (Seprate by Comma, Eg: 10000000,200000000,300000000)" v-model="selectedLogin" hide-details="auto"></v-text-field>
                        </v-col>
                    </v-row>

                    <v-row class="pa-4">
                        <v-col cols="4">
                            <v-btn v-on:click="fetchTransactionRecord()" :loading="btnLoading" color="primary">SEARCH</v-btn>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <v-card outlined>
                    <v-card-title style="border-bottom: 1px solid #404040;" class="pt-2 pb-2">
                        <div class="text-left" style="margin-left: 0; width: 50%; font-size: 16px; font-weight: normal;">
                            RESULT
                        </div>
                        <div class="text-right" style="margin-right: 0; width: 50%;">
                            <download-excel :data="tradesList" :name="'Search'">
                                <v-icon color="green" dark dense>
                                    mdi-microsoft-excel
                                </v-icon>
                            </download-excel>
                        </div>
                    </v-card-title>

                    <v-row>
                        <v-col cols="12">
                            <v-card flat>
                                <v-data-table dense :search="search" :headers="headers" :items="filteredDataSource" class="elevation-1"
                                    :items-per-page="10">
                                    <template v-slot:header.login="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`loginName`)">
                                                    <v-icon small :color="loginName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="loginName" class="px-4" type="text" ref="loginName"
                                                    label="Enter the search term" style="width: 100%;"></v-text-field>
                                                <v-btn @click="loginName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.book="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`bookName`)">
                                                    <v-icon small :color="bookName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="bookName" class="px-4" type="text" ref="bookName"
                                                    label="Enter the search term" style="width: 100%;"></v-text-field>
                                                <v-btn @click="bookName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                    <template v-slot:header.total_volume="{ header }">
                                        {{ header.text }}
                                        <v-menu offset-y :close-on-content-click="false" top>
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on" @click="setFocus(`volumeName`)">
                                                    <v-icon small :color="volumeName ? 'primary' : ''">
                                                        mdi-filter
                                                    </v-icon>
                                                </v-btn>
                                            </template>
                                            <div style="background-color: #1e1e1e; width: 280px; ">
                                                <v-text-field v-model="volumeName" class="px-4" type="text" ref="volumeName"
                                                    label="Total volume >=" style="width: 100%;"></v-text-field>
                                                <v-btn @click="volumeName = ''" small text color="primary" class="ml-2 mb-2">
                                                    Clean
                                                </v-btn>
                                            </div>
                                        </v-menu>
                                    </template>
                                </v-data-table>
                            </v-card>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
        </v-row>

    </div>
</template>
<script>
import * as apiBook from "@components/api/pk/book";
import * as apiSearchVolume from "@components/api/pk/search-volume";
import DataTable from "@components/search/DataTable";
import { mapState } from "vuex";
import { snackbar } from "@components/mixins/snackbar";
import { permission } from "@components/mixins/permission";
import { helper } from "@components/mixins/helper";

export default {
    components: { DataTable },
    mixins: [snackbar, permission, helper],
    data () {
        return {
            loginName: "",
            bookName: "",
            volumeName: "",
            showMoreStatus: false,
            selectedBrokerId: 1,
            selectedOffsetStart: 0,
            selectedLimit: 100,
            selectedOrderBy: "volume",
            selectedStartDate: null,
            selectedEndDate: null,
            selectedVolume: 0,
            selectedLogin: "",
            selectedBook: [],

            filterShowResult: [100, 200, 400, 600, 1000, 2000],
            btnLoading: false,
            isAsc: false,

            filterOrderBy: ["volume"],
            filterBookLists: [],

            tradesList: [],
            csvHeader: {
                login: "Login",
                book: "Book",
                total_volume: "Total Volume"
            },

            search: "",
            headers: [
                { text: "Login", value: "login" },
                { text: "Book", value: "book" },
                { text: "Total Volume", value: "total_volume" }
            ],
            brokers: [],
            root: 0
        };
    },
    computed: {
        ...mapState(["brokerMap"]),
        filteredDataSource () {
            const conditions = [];
            if (this.loginName) {
                conditions.push(this.filterLoginName);
            }
            if (this.bookName) {
                conditions.push(this.filterBookName);
            }
            if (this.volumeName) {
                conditions.push(this.filterVolumeName);
            }
            if (conditions.length > 0) {
                return this.tradesList.filter((item) => {
                    return conditions.every((condition) => {
                        return condition(item);
                    });
                });
            }
            return this.tradesList;
        }
    },
    watch: {
        selectedStartDate (nv, ov) {
            console.log(nv);
        },
        selectedBrokerId (nv, ov) {
            this.filterBookLists = this.getBookListByBrokerId(nv);
        }
    },
    methods: {
        async fetchTransactionRecord () {
            const self = this;

            const params = {
                volume: self.selectedVolume,
                broker_id: self.selectedBrokerId,
                offset: self.selectedOffsetStart,
                limit: self.selectedLimit,
                order_by: self.selectedOrderBy,
                start_date: self.selectedStartDate,
                end_date: self.selectedEndDate,
                is_asc: self.isAsc,
                login: self.selectedLogin,
                book: self.selectedBook,
                broker_name: self.getBrokerNameById(self.selectedBrokerId)
            };
            self.btnLoading = true;
            self.tradesList = [];
            apiSearchVolume.create(params).then((res) => {
                const data = res.data;
                if (data.length == 0) self.snackBarInfo("No data found!");
                self.tradesList = data;
                self.btnLoading = false;
            });
        },
        showMore () {
            this.showMoreStatus = !this.showMoreStatus;
        },
        setFocus (item) {
            setTimeout(() => {
                this.$refs[`${item}`].focus();
            }, 200);
        },
        filterLoginName (item) {
            return item.login.toString().includes(this.loginName);
        },
        filterBookName (item) {
            return item.book
                .toLowerCase()
                .includes(this.bookName.toLowerCase());
        },
        filterVolumeName (item) {
            return item.total_volume >= this.volumeName;
        }
    },
    created () {
        // 获取当前账户对应权限的broker及broker id
        const brokerPermission = this.loadDefaultBrokerData();
        this.selectedBrokerId = brokerPermission.selectedBrokerId;
        this.selectedBroker = brokerPermission.selectedBroker;
    },
    mounted () {
        const currentDate = new Date();
        this.selectedEndDate = new Date();
        const dateOffset = 24 * 60 * 60 * 1000 * 60; // 5 days
        const startDate = new Date();
        startDate.setTime(startDate.getTime() - dateOffset);
        this.selectedStartDate = startDate;
        this.filterBookLists = this.getBookListByBrokerId(
            this.selectedBrokerId
        );
    }
};
</script>
<style lang="">
</style>
