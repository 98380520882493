<template>
	<div class="home">
		<v-card>
			<v-container fluid>
				<v-row><v-col cols="12"></v-col></v-row>
                <VolumeSearch/>
            </v-container>
        </v-card>
    </div>
</template>
<script>
import VolumeSearch from "./component/ComponentVolumeSearch";
import { permission } from "@components/mixins/permission";
import Breadcrumbs from "@components/Breadcrumbs";

export default {
    components: { VolumeSearch, Breadcrumbs },
    mixins: [permission],
    data () {
        return {};
    },
    mounted () {}
};
</script>
