<template>
    <v-data-table
        dense
        :search="search"
        :headers="headers"
        :items="dataSource"
        class="elevation-1"
        :items-per-page="itemsPerPage"
    >
	</v-data-table>
</template>

<script>
export default {
    props: {
        search: "",
        headers: null,
        dataSource: null,
        itemsPerPage: null
    }
};
</script>
